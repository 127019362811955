<template>
  <div class="addMailSettings">
    <div style="background: #FFFFFF;padding: 15px;height: calc(100% - 60px);">
<!--      <div class="layTop d-flex">
        <div class="Title" style="margin-bottom: 10px;">{{ $t('INCREASE') }}</div>
      </div>-->

    </div>
  </div>
</template>

<script>
export default {
  name: "addMailSettings",
  props:{
    typeStatus: Number,
  },
  data() {
    return {
      type: 0,
      ruleForm: {
        name: '',
        supplier: '',
        contacts: ''
      },
      rules: {
        name: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
        supplier: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
      }
    }
  },
  created() {
    this.type = this.$route.query.type;
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  watch:{
    typeStatus(New) {
      alert('1')
      this.type = New;
    }
  }
}
</script>

<style scoped lang="scss">
.addMailSettings {
  //padding: 15px;
  height: 100%;
}
</style>
