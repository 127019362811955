var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(_vm._s(_vm.$t("Supplier Contact")))]
            )
          ]),
          [
            _c(
              "avue-crud",
              {
                attrs: {
                  option: _vm.tableOpt,
                  data: _vm.tableData,
                  "row-style": _vm.rowStyle,
                  page: _vm.page
                },
                on: {
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "current-change": _vm.currentChange
                },
                scopedSlots: _vm._u([
                  {
                    key: "menu",
                    fn: function(ref) {
                      var row = ref.row
                      var index = ref.index
                      var size = ref.size
                      var type = ref.type
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.delBtn(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("DELETE")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.editBtn(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Edit")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.addBtn(1, row, 2)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("ASSOCIATED RECIPIENT")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.addBtn(1, row, 3)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("ASSOCIATED CC")))]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.obj,
                  callback: function($$v) {
                    _vm.obj = $$v
                  },
                  expression: "obj"
                }
              },
              [
                _c(
                  "template",
                  {
                    staticStyle: { width: "calc(100% - 80px)" },
                    slot: "menuLeft"
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", {
                          staticClass: "fromOutDiv",
                          attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 }
                        }),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "padding-top": "10px",
                              "text-align": "right",
                              "align-self": "flex-end"
                            },
                            attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                          },
                          [
                            _c("el-badge", [
                              _c("img", {
                                staticStyle: {
                                  "padding-top": "4px",
                                  "padding-right": "15px",
                                  "padding-left": "15px",
                                  height: "1.7rem",
                                  width: "1.7rem"
                                },
                                attrs: {
                                  title: _vm.$t("ADD"),
                                  src: "/img/Bnt/add.png"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.addBtn(0)
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            )
          ],
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.type == 1 ? _vm.$t("INCREASE") : _vm.$t("ADD"),
                visible: _vm.dialogVisible,
                width: "50%",
                "before-close": _vm.handleClose
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                {},
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "150px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: _vm.$t("Function Name"),
                            prop: "functionName"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { disabled: _vm.type == 0 ? false : true },
                            model: {
                              value: _vm.ruleForm.functionName,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "functionName", $$v)
                              },
                              expression: "ruleForm.functionName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.functionCode
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("FUNCTION CODE"),
                                prop: "functionCode"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: _vm.type == 0 ? false : true
                                },
                                model: {
                                  value: _vm.ruleForm.functionCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "functionCode", $$v)
                                  },
                                  expression: "ruleForm.functionCode"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.typeStatus
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("Supplier"),
                                prop: "supplier"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "" },
                                  on: { change: _vm.chageSupplier },
                                  model: {
                                    value: _vm.ruleForm.supplier,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "supplier", $$v)
                                    },
                                    expression: "ruleForm.supplier"
                                  }
                                },
                                _vm._l(_vm.GetCurrCorporationData, function(
                                  v,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: v.shortName, value: v.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.typeStatus
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("CONTACTS"),
                                prop: "contacts"
                              }
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.contacts,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "contacts", $$v)
                                    },
                                    expression: "ruleForm.contacts"
                                  }
                                },
                                _vm._l(_vm.ListByCorpData, function(v, index) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: index,
                                      attrs: { label: v.userId, name: "type" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(v.userName) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { background: "#4D8AF0" },
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.submitForm("ruleForm")
                                }
                              }
                            },
                            [_vm._v("OK")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }