<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('Supplier Contact')}}</div>
            </div>
            <template>
                <avue-crud v-model="obj"
                           :option="tableOpt"
                           :data="tableData"
                           :row-style="rowStyle"
                           :page.sync="page"
                           @current-change="currentChange">

                    <template slot="menuLeft" style="width: calc(100% - 80px);">
                        <el-row :gutter="20">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px; text-align: right; align-self: flex-end;">
                                <el-badge>
                                    <img style="padding-top: 4px; padding-right: 15px; padding-left: 15px; height: 1.7rem; width: 1.7rem;" @click="addBtn(0)"
                                         :title="$t('ADD')" src="/img/Bnt/add.png" />
                                </el-badge>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button type="text" size="small" @click="delBtn(row)">{{ $t('DELETE') }}</el-button>
                        <el-button type="text" size="small" @click="editBtn(row)">{{ $t('Edit') }}</el-button>
                        <el-button type="text" size="small" @click="addBtn(1, row, 2)">{{ $t('ASSOCIATED RECIPIENT') }}</el-button>
                        <el-button type="text" size="small" @click="addBtn(1, row, 3)">{{ $t('ASSOCIATED CC') }}</el-button>
                    </template>

                </avue-crud>

            </template>
            <el-dialog :title="type == 1 ? $t('INCREASE') : $t('ADD')"
                       :visible.sync="dialogVisible"
                       width="50%"
                       :before-close="handleClose">

                <div class="" style="">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                        <el-form-item :label="$t('Function Name')" prop="functionName" style="width: 100%;">
                            <el-input v-model="ruleForm.functionName" style="width: 100%;"
                                      :disabled="type == 0 ? false : true"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('FUNCTION CODE')" prop="functionCode" style="width: 100%;" v-if="functionCode">
                            <el-input v-model="ruleForm.functionCode" style="width: 100%;"
                                      :disabled="type == 0 ? false : true"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Supplier')" prop="supplier" v-if="typeStatus">
                            <!--              <el-input v-model="ruleForm.supplier"></el-input>-->
                            <el-select v-model="ruleForm.supplier" placeholder="" style="width: 100%;" @change="chageSupplier">
                                <el-option v-for="(v, index) in GetCurrCorporationData" :label="v.shortName" :value="v.id"
                                           :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('CONTACTS')" prop="contacts" v-if="typeStatus">
                            <el-checkbox-group v-model="ruleForm.contacts">
                                <el-checkbox v-for="(v, index) in ListByCorpData" :label="v.userId" name="type"
                                             :key="index">
                                    {{ v.userName }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')" style="background: #4D8AF0;">OK</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-dialog>
        </basic-container>
    </div>
</template>

<script>
import {
  GetSetEmailFunction,
  GetSetEmailFunctions,
  PostSetEmailFunction,
  DeleteSetEmailFunction,
  PutSetEmailFunction,
  GetEmailContactListByCorp,
  GetCurrCorporation,
  GetCorporationList,
  PostEmailFunctionUsers
} from '@/api/maintain'
import AddMailSettings from "@/views/basis/addMailSettings";

export default {
  name: "mailSettings",
  components: {AddMailSettings},
  data() {
    return {
      dialogVisible: false,
      type: 0,
      typeStatus: false,
      functionCode: true,
      newType: 2,
      ruleForm: {
        id: 0,
        name: '',
        supplier: '',
        contacts: [],
        functionName: '',
        functionCode: ''
      },
      rules: {
        functionName: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
        functionCode: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
        supplier: [
          {required: true, message: this.$t('Pleaseselect'), trigger: 'blur'},
        ],
        contacts: [
          {type: 'array', required: true, message: this.$t('Please enter'), trigger: 'change'}
        ],
      },
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      size: 10,
      loading: false,
      total: 0,
      tableData: [],
      ListByCorpData: [],
        GetCurrCorporationData: [],
        GetCorporationListData: [],
      tableOpt: {
          page: true,
          refreshBtn: false,
          delBtn: false,
          emptyText: this.$t('No Data'),
          menuTitle: this.$t('OPERATION'),
          menuWidth: 240,
          align: 'center',
          columnBtn: false,
          simplePage: false,
          editTitle: this.$t('Edit'),
          addTitle: this.$t('Add'),
          addBtn: false,
          editBtn: false,
          header: true,
          saveBtn: false,
          updateBtn: false,
          cancelBtn: false,
          labelPosition: 'top',
        column: [
          {
            label: this.$t('FUNCTION CODE'),
            prop: 'functionCode',
          },
          {
            label: this.$t('FUNCTION NAME'),
            prop: 'functionName',
          },
          {
            label: this.$t('TEMPLATE NAME'),
            prop: 'templateName',
          }
        ]
      },
      obj: {},
    }
  },
  created() {
    var page = this.page;
    this.list();
      GetCurrCorporation({}).then((respoonse) => {
          this.GetCurrCorporationData.push(respoonse.data)
      });
      GetCorporationList({}).then((respoonse) => {
          this.GetCorporationListData = respoonse.data
      });
  },
  methods: {
    chageSupplier(row) {
      GetEmailContactListByCorp(this.ruleForm.supplier).then((response) => {
        console.log(response)
        this.ListByCorpData = response.data
      })
    },
    submitForm(formName) {
      var ruleForm = this.ruleForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');

          if (this.newType == 2 || this.newType == 3) {
            PostEmailFunctionUsers({
              "functionCode": ruleForm.functionCode,
              corporationId: ruleForm.supplier,
              isCopy: this.newType == 2 ? 0 : 1,
              userIds: ruleForm.contacts

            }).then((response) => {
                if (response.data.statusCode == 200 && !response.data.isError) {
                    this.$message({ message: this.$t('OperationSuccess'), type: "success" });
                } else {
                    this.$message({ message: this.$t('OperationFailed'), type: "warning" });
                }
              this.resetForm('ruleForm')
            });

            return ''
          }

          if (ruleForm.id) {
            PutSetEmailFunction({
              "id": ruleForm.id,
              "functionCode": ruleForm.functionCode,
              "functionName": ruleForm.functionName,
              "templateName": "",
              "status": 0
            }).then((response) => {
                if (response.data.statusCode == 200 && !response.data.isError) {
                    this.$message({ message: this.$t('OperationSuccess'), type: "success" });
                    this.page.total = 0;
                    this.list();
                } else {
                    this.$message({ message: this.$t('OperationFailed'), type: "warning" });
                }
              this.resetForm('ruleForm')
            })
          } else {
            PostSetEmailFunction({
              "id": '',
              "functionCode": ruleForm.functionCode,
              "functionName": ruleForm.functionName,
              "templateName": "",
              "status": 0
            }).then((response) => {
              // console.log(response);
                if (response.data.statusCode == 200 && !response.data.isError) {
                    this.$message({ message: this.$t('OperationSuccess'), type: "success" });
                    this.page.total = 0;
                    this.list();
                } else {
                    this.$message({ message: this.$t('OperationFailed'), type: "warning" });
                }
              this.resetForm('ruleForm')

            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.dialogVisible = false;
      this.ruleForm = {
        id: 0,
        name: '',
        supplier: '',
        contacts: [],
        functionName: '',
        functionCode: ''
      }
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.dialogVisible = false;
      this.resetForm('ruleForm')
    },
    list(page, size) {

      GetSetEmailFunctions(this.page.currentPage, this.page.pageSize).then(res => {
        this.tableData = res.data.setEmailFunctions;
        this.page.total = res.data.totalCount;
        this.page.pageSize = res.data.pageSize;
      }).catch(erro => {
        console.log(erro)
      })
    },
    rowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },
    currentChange(val) {
      this.page.currentPage = val
      this.list()
      // this.getOperateLogList();
    },
    delBtn(row) {
        DeleteSetEmailFunction(row.id).then((response) => {
            if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({ message: this.$t('OperationSuccess'), type: "success" });
                this.page.total = 0;
                this.list();
            } else {
                this.$message({ message: this.$t('OperationFailed'), type: "warning" });
            }
      })
    },
    associated(row) {
      this.dialogVisible = true;
      this.functionCode = true;
      this.newType = 3;
      this.typeStatus = true;
      this.type = type;
      var ruleForm = this.ruleForm;
      ruleForm.functionCode = row.functionCode;
      ruleForm.functionName = row.functionName
    },
    addBtn(type, row, newType) {
      this.functionCode = true;
      this.newType = newType
      this.typeStatus = type == 0 ? false : true
      var ruleForm = this.ruleForm;
      this.type = type;
      this.dialogVisible = true;
      ruleForm.functionCode = row.functionCode;
      ruleForm.functionName = row.functionName
      /*this.$router.push({
        path: '/basic/addMailSettings?type=' + type
      })*/
    },
    editBtn(row) {
      this.functionCode = false;
      this.typeStatus = false;
      this.type = 0;
      this.dialogVisible = true;
      this.ruleForm.functionCode = row.functionCode;
      this.ruleForm.functionName = row.functionName;
      this.ruleForm.id = row.id;
      this.newType = 0;
    },
    LogDetailinfo(row) {
      console.log(row)
    },
  }
}
</script>

<style scoped lang="scss">
.pvhFactory {
  padding: 15px;

  .layTop {
    justify-content: space-between;
  }
}

.d-flex {
  display: flex;
}

/deep/ thead tr {
  background: none !important;
}

/deep/ .el-table th, .el-table tr {
  background: none !important;
  color: #fff;
}

/deep/ thead tr {
  background: linear-gradient(325deg, #212145 0%, #565682 100%) !important;
}
</style>
